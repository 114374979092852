import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, of as observableOf } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import { PrimaryInsuranceAndMembershipIsDpcGraphQLService } from '@app/appointment/primary-insurance-and-membership-is-dpc-graphql.service';
import { FeatureFlags, FeatureFlagVariantsWithOn } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { BillingAndInsuranceGraphQLService } from '@app/home/billing-and-insurance/billing-and-insurance-graphql.service';

import { User } from '../user';

export const InsuranceStatus = {
  SELF_PAY: 'Self Pay',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
} as const;
export type InsuranceStatus = typeof InsuranceStatus[keyof typeof InsuranceStatus];

export interface InsuranceCaptureValues {
  variant: FeatureFlagVariantsWithOn;
  primaryInsuranceStatus: string | undefined;
}

export interface InsuranceData {
  insuranceStatus: InsuranceStatus;
  insuranceCompany?: string | null;
  memberId?: string | null;
  copay?: number | null;
}

export enum InsuranceCaptureSource {
  REVIEW_BOOKING,
  BOOKING_CONFIRMATION,
  APPOINTMENT_INVENTORY,
}

export interface InsuranceCaptureNavigateParams {
  source: InsuranceCaptureSource;
  confirmationId?: string;
  replaceUrl?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceCaptureService {
  user$: Observable<User>;
  private _source: InsuranceCaptureSource;
  private _confirmationId: string;

  readonly OFF_STATE: InsuranceCaptureValues = {
    variant: FeatureFlagVariantsWithOn.OFF,
    primaryInsuranceStatus: undefined,
  };

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private primaryInsuranceAndMembershipIsDpcGraphQLService: PrimaryInsuranceAndMembershipIsDpcGraphQLService,
    private billingAndInsuranceGraphQLService: BillingAndInsuranceGraphQLService,
    private userService: UserService,
    private membershipService: MembershipService,
    private router: Router,
    private links: LinksService,
  ) {
    this.user$ = this.userService.getUser();
  }

  get source(): InsuranceCaptureSource {
    return this._source;
  }

  set source(source: InsuranceCaptureSource) {
    this._source = source;
  }

  get confirmationId(): string {
    return this._confirmationId;
  }

  set confirmationId(value: string) {
    this._confirmationId = value;
  }

  isSourceReviewBooking(): boolean {
    return this._source === InsuranceCaptureSource.REVIEW_BOOKING;
  }

  isSourceBookingConfirmation(): boolean {
    return this._source === InsuranceCaptureSource.BOOKING_CONFIRMATION;
  }

  isSourceAppointmentInventory(): boolean {
    return this._source === InsuranceCaptureSource.APPOINTMENT_INVENTORY;
  }

  updateLdUserAndGetInsuranceCaptureFlagEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined });
    }

    return combineLatest([
      this.user$,
      this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch(),
      this.membershipService.getMembership(),
    ]).pipe(
      switchMap(([user, graphqlResult, membership]) => {
        const primaryInsuranceStatus = graphqlResult.data.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus });
        }

        return from(
          this.launchDarklyService.updateUser({
            user,
            membership,
            customAttributes: { primaryInsuranceStatus },
          }),
        ).pipe(
          take(1),
          switchMap(() => this.insuranceCaptureFlagVariant$()),
          map(variant => ({ variant, primaryInsuranceStatus })),
        );
      }),
      catchError(() => observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined })),
    );
  }

  insuranceCaptureTestModeFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$<boolean>(FeatureFlags.INSURANCE_CAPTURE_WEB_TEST_MODE, false);
  }

  insuranceCaptureFullPageFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(map(result => result === FeatureFlagVariantsWithOn.ON_VARIANT || result === FeatureFlagVariantsWithOn.ON));
  }

  insuranceCaptureFlowAfterInventorySelectionEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.insuranceCaptureFlowAfterInventorySelectionValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  insuranceCaptureFlowAfterInventorySelectionValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf(this.OFF_STATE);
    }

    return combineLatest([
      this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      ),
      this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch({}, { fetchPolicy: 'network-only' }),
    ]).pipe(
      map(([flagResult, graphqlResult]) => {
        const primaryInsuranceStatus = graphqlResult.data?.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return this.OFF_STATE;
        }

        return {
          variant: primaryInsuranceStatus === InsuranceStatus.SELF_PAY ? flagResult : FeatureFlagVariantsWithOn.OFF,
          primaryInsuranceStatus,
        };
      }),
    );
  }

  insuranceCaptureEntryPointFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_INVENTORY_ENTRY_POINT,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(map(result => result === FeatureFlagVariantsWithOn.ON_VARIANT || result === FeatureFlagVariantsWithOn.ON));
  }

  setParamsAndNavigateToManageInsurance(options: InsuranceCaptureNavigateParams): void {
    this.source = options.source;
    if (options.confirmationId) {
      this.confirmationId = options.confirmationId;
    }
    this.router.navigate([this.links.manageInsurance], {
      replaceUrl: options.replaceUrl ?? false,
    });
  }

  handleNavigationAfterInventorySelection(insuranceCaptureFlowAfterInventorySelectionEnabled: boolean) {
    if (insuranceCaptureFlowAfterInventorySelectionEnabled) {
      this.setParamsAndNavigateToManageInsurance({
        source: InsuranceCaptureSource.APPOINTMENT_INVENTORY,
        replaceUrl: true,
      });
    } else {
      this.router.navigate([this.links.appointmentReview], { replaceUrl: true });
    }
  }

  private insuranceCaptureFlagVariant$(): Observable<FeatureFlagVariantsWithOn> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
      FeatureFlags.INSURANCE_CAPTURE_WEB,
      FeatureFlagVariantsWithOn.OFF,
    );
  }

  getPrimaryInsurance(): Observable<InsuranceData> {
    return this.billingAndInsuranceGraphQLService.watch({}, { fetchPolicy: 'network-only' }).valueChanges.pipe(
      map(result => {
        const primaryInsurance = result.data?.patient?.primaryInsurance;

        let insuranceStatus: InsuranceStatus;
        if (primaryInsurance?.insuranceType?.isSelfPay) {
          insuranceStatus = InsuranceStatus.SELF_PAY;
        } else if (primaryInsurance?.isPendingReview) {
          insuranceStatus = InsuranceStatus.PENDING;
        } else {
          insuranceStatus = InsuranceStatus.VERIFIED;
        }

        return {
          insuranceStatus,
          insuranceCompany: primaryInsurance?.name,
          memberId: primaryInsurance?.subscriberNumber,
          copay: primaryInsurance?.copay,
        };
      }),
    );
  }
}
